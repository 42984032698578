@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"





























.assignment-section-layout
  margin-bottom: $size-xl

  &__title
    color: $color-ink
    margin-bottom: $size-xs

  &__subtitle
    display: block
    color: $color-ink-light
    margin-bottom: $size-m
