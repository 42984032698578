@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"



















































































































=flex
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  padding:
    top: 50px
    bottom: $element-size-l

.h2
  font-size: $font-size-heading-2-small

  +mq-s--mf
    font-size: $font-size-heading-2

.h5
  font-size: $font-size-heading-6

  +mq-s--mf
    font-size: $font-size-heading-5

.header
  background-color: linear-gradient(250.13deg, #1F3161 39.85%, #0A4891 99.22%)
  color: $color-white

  &__toolbar
    padding: $size-s 0
    border-bottom: 1px solid transparentize($color-white, 0.75)

    .sas-wrapper
      +flex-space-between

    &__left
      +flex-center-start

    &__right
      +flex-center-start

    .sas-skeleton-loader
      margin: 7px 0

  &__body
    padding:
      top: 40px
      bottom: $size-m

    +mq-l--mf
      +flex

  &__right-slot
    margin-top: $size-s

    +mq-m--mf
      margin-top: $size-m

  &.--theme-light
    background: $color-white
    color: $color-ink

    .header__toolbar
      border-bottom: 1px solid transparentize($color-ink-lightest, 0.5)

      .sas-skeleton-loader
        margin: 5px 0

    .header__content
      .h5
        color: $color-ink-light

  &.--layout-subtitle
    .header__body
      padding-top: $size-l

  &.--is-flex
    .header__body
      +flex
