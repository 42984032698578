@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/variables";
@import "~@/styles/mixins";
@import "~@/styles/media";



































































































.proposed-assignment-historic {
  width: 100%;
  min-height: calc(100vh - #{$menu-height});

  &__wrapper {
    padding: {
      top: $size-l;
      bottom: $end-space;
    }
  }

  ::v-deep .header {
    &__toolbar {
      border-bottom: none;
    }

    .h5 {
      color: transparentize($color-white, 0.2);
      font-size: $font-size-heading-5;

      @include mq-s--mf {
        font-size: $font-size-heading-4;
      }
    }
  }
}
